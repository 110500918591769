module.exports = {
    'title': 'Manage Email Subscription',
    'label_email': 'Email Address',
    'label_unsub_choice': 'You have chosen to:',
    'label_unsub_marketing': 'Remove me from all promotional messages',
    'label_unsub_monthly': 'Remove me from Monthly Account Summary messages',
    'label_btn_submit': 'UNSUBSCRIBE',
    'label_btn_redirect': 'BACK TO HOME',
    'notification_success_title': 'Unsubscribe Confirmed',
    'label_success_email': ' has been unsubscribed from the following mailing list:',
    'bullet_ayana_rewards_promotion': 'AYANA Rewards promotions',
    'bullet_ayana_rewards_monthly': 'AYANA Rewards Monthly Account Summary',
    'label_success_resubscribe': 'You can resubscribe through the Profile page.',
    'notification_error_title': 'Error'
}